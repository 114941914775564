import React, { useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';
import Portal from './portal';
import { AiOutlineCloseCircle } from '@react-icons/all-files/ai/AiOutlineCloseCircle';
import { closeStyle, lightContent, darkContent } from './modal.module.css';
import { ThemeContext } from './ThemeContext';

const CloseButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 3px;
  right: 7px;
  color: ${(props) => (props.theme.name === 'dark' ? '#848484' : '#848484')};
  margin: 5px;
`;

const Modal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);

  React.useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        handleClose();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => handleClose(),
    };
  });

  const handleOpen = () => {
    setDisplay(true);
    document.body.style.overflow = 'hidden';
  };

  const handleClose = () => {
    const content = document.getElementsByClassName('modal-content');
    if (content && content.length) {
      document.body.style.overflow = 'unset';
      if (content) {
        content[0].classList.add('modal-close');
        setTimeout(() => {
          setDisplay(false);
        }, 200);
      } else {
        setDisplay(false);
      }
    }
  };

  if (display) {
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <Portal>
            <div className="modal-backdrop"></div>
            <div className={`modal-content ${theme.name === 'dark' ? darkContent : lightContent}`}>
              {props.children}
              <CloseButtonWrapper theme={theme} onClick={handleClose} className={closeStyle}>
                <AiOutlineCloseCircle size={30} />
              </CloseButtonWrapper>
            </div>
          </Portal>
        )}
      </ThemeContext.Consumer>
    );
  }

  return null;
});

export default Modal;
