import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';

export const MainContainer = styled(Row)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: auto;
  max-height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  @media (min-width: 600px) {
    max-width: 750px;
  }

  &::-webkit-scrollbar {
    width: 11px;
    background: ${(props) => (props.theme.name === 'dark' ? '#222222' : '#e4e4e4')};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => (props.theme.name === 'dark' ? '#222222' : '#e4e4e4')};
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 100px;
    border: ${(props) => (props.theme.name === 'dark' ? '6px solid rgba(255, 255, 255, 0.18)' : 'darkgrey')};
    border-left: 0;
    border-right: 0;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    background: ${(props) => (props.theme.name === 'dark' ? 'rgba(98,98,98)' : 'darkgrey')};
  }
`;
export const LogoImage = styled(Col)`
  display: inline-block;
  height: auto;
  position: relative;
  margin-bottom: 20px;
`;
export const TitleWrapper = styled.div`
  position: absolute;
  height: auto;
  width: auto;
  bottom: 10px;
  right: 35px;
`;
export const ProjectTitle = styled.h2`
  text-shadow: ${(props) =>
    props.theme.name === 'dark'
      ? '1px 1px 8px #000,1px 1px 8px #000,1px 1px 8px #000,1px 1px 8px #000'
      : '1px 1px 8px #fff, 1px 1px 8px #fff, 1px 1px 8px #fff'};
`;
export const Content = styled(Col)`
  margin: 5px 5px 25px 5px;
  width: auto;
`;
