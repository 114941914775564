import * as React from 'react';
import styled from 'styled-components';
import { ThemeContext } from './ThemeContext';

import { Card } from 'react-bootstrap';
import { cardHoverDark, cardHoverLight } from './ProjectCard.module.css';
import './cardStyle.css';

import { parseInt } from 'lodash';
import { GetProjectsPhotos } from './QueryProjectsImages';
import StackImagesManager from './StackImagesManager';
import ProjectsImageManager from './ProjectsImageManager';

const isBrowser = () => typeof window !== "undefined"
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const CardWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  text-align: left;
  box-shadow: ${(props) => (props.theme.name === 'dark' ? '3px 3px 14px 1px #fff' : ' 3px 3px 14px 1px #888;')};
  max-width: ${(props) => (props.screenwidth)};
  height: fit-content;
  min-height: 470px;
  margin: 15px;
  padding: 10px;
  background: ${(props) => (props.theme.name === 'dark' ? '#626262fc' : '#d1d5d8')};
  transition: all 0.5s ease;
`;

const ProjectDate = styled.span`
  position: absolute;
  color: white;
  right:2;
  right: 20px;
  top: ${(props) => (props.screenwidth)};
  font-weight: 600;
  text-shadow: 0px 2px 11px darkblue, 1px 1px 10px blue;
  font-size: 16px;
  `;

export const ProjectCard = (props) => {
  useWindowSize();

  let scrnWidth = isBrowser() && window.innerWidth;

  const projectsImgData = GetProjectsPhotos(props.image_title);

  const rating = parseInt(props.rating);
  const isActive = props.isActive?.toLowerCase() === 'true' ? true : false;
  const stack = props.stack?.split(',');
  stack &&
    stack.forEach(function (l, i) {
      this[i] = this[i].trim().toLowerCase();
      !this[i] && this.splice(i, 1);
    }, stack);

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <CardWrapper screenwidth={ scrnWidth > 500 ? '238px' : '293px'} theme={theme} className={theme.name === 'dark' ? cardHoverDark : cardHoverLight }>
          {rating === 5 && (
            <div className="ribbon gold">
              <span>SPECIAL !</span>
            </div>
          )}
          {isActive && (
            <div className="ribbon2 new">
              <span>ACTIVE</span>
            </div>
          )}

          {theme.name === 'dark' ? ProjectsImageManager(projectsImgData, props.image_title) : (['shaylavi', 'stickyar'].includes(props.image_title.toLowerCase()) ? ProjectsImageManager(projectsImgData, `${props.image_title}2`) : ProjectsImageManager(projectsImgData, props.image_title))}
          <div className="card-body bodyWrapper">
            <h5 className="card-title">{props.title}</h5>
            <ProjectDate screenwidth={ scrnWidth > 500 ? '123px' : '157px'} className="project-date">{`${monthNames[props.date.getMonth()]} ${props.date.getFullYear()}`}</ProjectDate>
            <p className="card-text">{props.subtitle}</p>
          </div>
          <div className="btnWrapper">
            <div className="stack-wrapper">
              {stack.map((item) => {
                return StackImagesManager(item, theme);
              })}
            </div>
            <button
              onClick={props.clickHandler}
              id={props.id}
              className={`btn ${theme.name === 'dark' ? 'btn-info' : 'btn-secondary'}`}
            >
              More details
            </button>
          </div>
        </CardWrapper>
      )}
    </ThemeContext.Consumer>
  );
};

function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: 100,
    height: 100,
  });
  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    isBrowser() && window.addEventListener("resize", handleResize);
    handleResize();
    return () => isBrowser() && window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}