import * as React from 'react';

import {
  Ul,
  Hr,
  Titles,
  TitlesWrapper,
  Content,
  Li,
  Bullet,
} from './ContentStyles';

export const Education = () => {
  return (
    <>
      <Titles md={3}>
        <TitlesWrapper>
          <h2>Education</h2>
        </TitlesWrapper>
        <Hr />
      </Titles>
      <Content md={6}>
        <Ul>
          <Li>
            <Bullet>2017 - 2020 📜</Bullet>
            <div>
              Bachelor of Information Technologies, <b>AIT Melbourne</b>, Australia
            </div>
          </Li>
          <Li>
            <Bullet>2009 - 2010 📜</Bullet>
            <div>MCPD Course - .NET C#, <b>Ness Technologies</b>, Israel</div>
          </Li>
          <Li>
            <Bullet style={{minWidth: '100px', justifyContent:"flex-end"}}>2008 📜</Bullet>
            <div><b>Self-tought</b> C#, .NET & ASP</div>
          </Li>
        </Ul>
      </Content>
    </>
  );
};
