import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { customCardImgTop } from './ImagesManager.module.css';

const ProjectsImageManager = (imageData, imageName) => {

  var projectsImages;

  if (imageData) {
    const imgDta = getImage(imageData[0].childImageSharp)

    projectsImages = [
      <GatsbyImage image={imgDta} key="StickyAR" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="CSH" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="ShayLavi" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="aittimetable" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="clicknconnect" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="ecotraveller" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="freecodecamp" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="happier-app" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="happier-services" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="spacemadness" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={imgDta} key="homemanager" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
    ]

    if (imageData.length > 1) {
      projectsImages.push(<GatsbyImage image={getImage(imageData[1].childImageSharp)} key="ShayLavi2" className={'card-img-top ' + customCardImgTop} alt={imageName} />,
      <GatsbyImage image={getImage(imageData[1].childImageSharp)} key="StickyAR2" className={'card-img-top ' + customCardImgTop} alt={imageName} />)
    }

    } else return null;

    const filtered = projectsImages.filter((i) => i.key.toLowerCase() === imageName.toLowerCase());
  return filtered.length > 0 ? filtered[0] : [];
};

export default ProjectsImageManager;
