import React from 'react';
import styled from 'styled-components';

const VideoContainer = styled.div`
`;
const VideoIframe = styled.iframe`
min-height: 400px;
width: 100%;
`;

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <VideoContainer>
    <VideoIframe
      src={`${videoSrcURL}?modestbranding=1&iv_load_policy=3&autohide=1&showinfo=0&controls=0`}
      title={videoTitle}
      allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </VideoContainer>
);
export default Video;
