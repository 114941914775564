import { useStaticQuery, graphql } from 'gatsby';

const data = graphql`
  query {
    allMongodbPortfolioProjects {
      nodes {
        id
        mongodb_id
        challenges
        date
        links
        description
        grade
        rating
        active
        stack
        images
        title
        subtitle
        video
        stack_detailed
        image_title
        project_id
      }
    }
  }
`;

export const GetAllDBRecords = () => {
  return useStaticQuery(data).allMongodbPortfolioProjects.nodes;
};
