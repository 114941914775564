import React from 'react';
import { GetGalleryPhotos } from './QueryGalleryImages';
import Gallery from '@browniebroke/gatsby-image-gallery'

const GalleryPage = (props) => {
  const allImages = GetGalleryPhotos(props.data.project_id);
  const images = allImages?.edges.map(({ node }) => node.childImageSharp)

//   const GalleryImagesStyle = styled.div`
//   display: 'flex',
//   margin: '15px',
//   flex-direction: 'row',
//   flexWrap: 'nowrap',
//   align-content: 'center',
//   justify-content: 'flex-start',
//   align-items: 'baseline',
//   position: 'relative',
//   width: 'auto',
//   overflow-x: 'auto',
// `;

//   const CustomWrapper = ({ children, onClick }) => (
//     <div className={GalleryImagesStyle} onClick={onClick}>
//       {children}
//     </div>
//   )

  return (
    <div>
      <Gallery images={images} />
    </div>
  );
};
export default GalleryPage;
