import { useStaticQuery, graphql } from 'gatsby';

const data = graphql`
  query {
    projects_images: allFile(sort:{fields:name,order:ASC}, filter: { relativeDirectory: { in: "projects" } }) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            breakpoints: [750, 1080, 1366, 1920]
            layout: FULL_WIDTH
            transformOptions: { fit: COVER }
            )
          id
        }
        name
      }
    }
  }
`;

export const GetProjectsPhotos = (title) => {
  const queryResults = useStaticQuery(data);
  const filteredResult = queryResults['projects_images']?.nodes.filter((x)=>x['name'].includes(title));

  return filteredResult.length > 0 ? filteredResult : null;
};
