import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const Titles = styled(Col)`
  display: flex;
  align-items: center;
  width: min-content;
  min-width: 250px;
  justify-content: space-between;
  @media (max-width: 770px) {
    height: min-content;
    justify-content: center;
    width: 100%;
    text-align: center;
  }
`;
export const TitlesWrapper = styled.div`
  @media (max-width: 770px) {
    border-bottom: 1px solid;
  }
`;

export const Content = styled(Col)`
  display: flex;
  text-align: right;
  @media (max-width: 770px) {
    justify-content: center;
    width: 100%;
    margin-top: 10px;
  }
`;
export const Bullet = styled.div`
  margin-right: 6px;
  min-width: fit-content;
  display: flex;
  align-items: center;
`;
export const Li = styled.li`
  margin-bottom: 11px;
  display: flex;
  width: 100%;
`;
export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: flex-start;
  text-align: left;
  line-height: 1.25;
}
`;
export const Hr = styled.div`
  height: 100%;
  border-left: 1px solid;
  width: 1px;
  @media (max-width: 770px) {
    height: 100%;
    display: none;
  }
`;
