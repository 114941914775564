import { useStaticQuery, graphql } from 'gatsby';

const data = graphql`
query {
  happier_app:
    allFile(sort:{fields:name,order:ASC}, filter: { relativeDirectory: { in: "gallery/happierapp" } }) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  csh:
    allFile(sort:{fields:name,order:ASC}, filter: { relativeDirectory: { in: "gallery/csh" } }) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  aittimetable:
    allFile(sort:{fields:name,order:ASC}, filter: { relativeDirectory: { in: "gallery/aittimetable" } }) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  clicknconnect:
    allFile(sort:{fields:name,order:ASC}, filter: { relativeDirectory: { in: "gallery/clicknconnect" } }) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    homemanager:
    allFile(sort:{fields:name,order:ASC}, filter: { relativeDirectory: { in: "gallery/homemanager" } }) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export const GetGalleryPhotos = (title) => {
  return useStaticQuery(data)[title];
};
