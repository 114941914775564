import styled from 'styled-components';

export const NewsWrapperContent = styled.div`
  background: ${(props)=> ( props.theme.name === 'dark' ? 'rgba(98,98,98)' : '#e4e4e4' )};
  font-color: ${(props)=> ( props.theme.name === 'dark' ? '#fff' : '#000' )};
  position: relative;
  flex-flow: column;
  height: 320px;
  border-radius: 0px 18px 18px 0px;
  font-size: 14px;
  text-shadow: 1px 1px 15px blue, 1px 1px 3px #777;
  transition:width .35s;
  -moz-transition:width .35s; /* Firefox 4 */
  -webkit-transition:width .35s; /* Safari and Chrome */
  -o-transition:width .35s; /* Opera */
  `;

export const TextWrapper = styled.div`
  flex-direction: column;
  display:flex;
  visibility:normal;
  transition: opacity .50s .25s;
  -moz-transition: opacity .50s .25s; /* Firefox 4 */
  -webkit-transition: opacity .50s .25s; /* Safari and Chrome */
  -o-transition: opacity .50s .25s; /* Opera */
  `;

export const NewsWrapperMain = styled.div`
  height: 320px;
  position: fixed;
  left: 0;
  top: 70px;
  display: flex;
  flex-flow: row;
  align-items: center;
  z-index: 100;
  `;

export const NewsWrapperToggler = styled.div`
  height: 40px;
  width: 40px;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  transition: filter .50s;
  -moz-transition: filter .50s; /* Firefox 4 */
  -webkit-transition: filter .50s; /* Safari and Chrome */
  -o-transition: filter .50s; /* Opera */
`;

export const Title = styled.h5`
    text-decoration: underline;
    font-weight: lightbold;
    width:100%;
    font-size: 16px;
    text-align: center;
    text-shadow: 1px 1px 20px black, 1px 1px 3px #777;
    height: 25px;
    `;

export const PlainText = styled.p`
    line-height: 1;
    text-shadow: 1px 1px 20px grey, 1px 1px 3px #777;
`;
