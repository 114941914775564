import * as React from 'react';

import styled from 'styled-components';
import { Row } from 'react-bootstrap';

import { Intro } from './Intro';
import { Experience } from './Experience';
import { Education } from './Education';
import { Skills } from './Skills';
import { Projects } from './Projects';
import { NewsWrapper } from './newswrapper';

const ContentRow = styled(Row)`
  display: flex;
  margin: 0 0 0 0;
`;
const Spacer = styled.div`
  min-height: 40px;
  height: 50px;
  width: 100%;
`;

export const MainContent = () => {
  return (
    <>
      <NewsWrapper />
      <ContentRow className="justify-content-md-center">
        <Intro />
        <Spacer />
      </ContentRow>
      <ContentRow className="justify-content-md-center">
        <Experience />
        <Spacer />
      </ContentRow>
      <ContentRow className="justify-content-md-center">
        <Education />
        <Spacer />
      </ContentRow>
      <ContentRow className="justify-content-md-center">
        <Skills />
        <Spacer />
      </ContentRow>
      <ContentRow className="justify-content-md-center">
        <Projects />
        <Spacer />
        <Spacer />
      </ContentRow>
    </>
  );
};
