import * as React from 'react';
import { isEmpty, isNull } from 'lodash';

import { stackIconLightMode, stackIconDarkMode, stackImgStyle } from './ProjectCard.module.css';

import androidSVG from '../images/stack/android.svg';
import angularSVG from '../images/stack/angular.svg';
import arduinoSVG from '../images/stack/arduino.svg';
import awsSVG from '../images/stack/aws.svg';
import BootstrapSVG from '../images/stack/Bootstrap.svg';
import chartJSSVG from '../images/stack/chartjs.svg';
import CSharpSVG from '../images/stack/CSharp.svg';
import cssSVG from '../images/stack/css.svg';
import dotnetSVG from '../images/stack/dotnet.svg';
import firebaseSVG from '../images/stack/firebase.svg';
import facebookSVG from '../images/stack/facebook.svg';
import flutterSVG from '../images/stack/flutter.svg';
import GraphQLSVG from '../images/stack/GraphQL.svg';
import gatsbySVG from '../images/stack/gatsby.svg';
import herokuSVG from '../images/stack/heroku.svg';
import htmlSVG from '../images/stack/html.svg';
import iosSVG from '../images/stack/ios.svg';
import javaSVG from '../images/stack/java.svg';
import javascriptSVG from '../images/stack/javascript.svg';
import kinectSVG from '../images/stack/kinect.svg';
import moleculerSVG from '../images/stack/moleculer.svg';
import mongodbSVG from '../images/stack/mongodb.svg';
import mysqlSVG from '../images/stack/mysql.svg';
import nodeJSSVG from '../images/stack/nodejs.svg';
import reduxSVG from '../images/stack/redux.svg';
import phpSVG from '../images/stack/php.svg';
import socketIOSVG from '../images/stack/socketio.svg';
import sqlSVG from '../images/stack/sql.svg';
import paperSVG from '../images/stack/paper.svg';
import pythonSVG from '../images/stack/python.svg';
import TypescriptSVG from '../images/stack/Typescript.svg';
import reactSVG from '../images/stack/react.svg';
import vuforiaSVG from '../images/stack/vuforia.svg';
import unitySVG from '../images/stack/unity.svg';
import zoomSVG from '../images/stack/zoom.svg';
import raspberrySVG from '../images/stack/raspberry.svg';
import spotifySVG from '../images/stack/spotify.svg';
import iotSVG from '../images/stack/iot.svg';

const StackImagesManager = (imageName, activeTheme) => {

  if (!isNull(imageName) && !isEmpty(imageName)) {
  const images = [
    <img
      key="iot"
      src={iotSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="raspberry"
      src={raspberrySVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="spotify"
      src={spotifySVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="android"
      src={androidSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="arduino"
      src={arduinoSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="angular"
      src={angularSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="aws"
      src={awsSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="Bootstrap"
      src={BootstrapSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="chartJS"
      src={chartJSSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="CSharp"
      src={CSharpSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="css"
      src={cssSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="dotnet"
      src={dotnetSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="firebase"
      src={firebaseSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="facebook"
      src={facebookSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="flutter"
      src={flutterSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="GraphQL"
      src={GraphQLSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="heroku"
      src={herokuSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="html"
      src={htmlSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="ios"
      src={iosSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="java"
      src={javaSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="javascript"
      src={javascriptSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="kinect"
      src={kinectSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="moleculer"
      src={moleculerSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="mongodb"
      src={mongodbSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="mysql"
      src={mysqlSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="nodeJS"
      src={nodeJSSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="redux"
      src={reduxSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="php"
      src={phpSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="socketIO"
      src={socketIOSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="sql"
      src={sqlSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="paper"
      src={paperSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="python"
      src={pythonSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="Typescript"
      src={TypescriptSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="react"
      src={reactSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="vuforia"
      src={vuforiaSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="unity"
      src={unitySVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="gatsby"
      src={gatsbySVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />,
    <img
      key="zoom"
      src={zoomSVG}
      className={`${stackImgStyle} ${
        activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
      }`}
      alt={imageName}
    />
  ];

  const filtered = images.filter((i) => i.key.toLowerCase() === imageName.toLowerCase());
  return filtered.length > 0 ? filtered[0] : [];
} else return null;
};

export default StackImagesManager;
