import * as React from 'react';

import { ThemeContext } from './ThemeContext';
import newspaper from '../images/stack/rolled-paper.svg';
import styled from 'styled-components';

import './newswrapper.css';
import { PlainText, NewsWrapperContent, NewsWrapperMain, NewsWrapperToggler, TextWrapper, Title } from './newswrapperStyles.js';
import Draggable from 'react-draggable';
import { AiOutlineCloseCircle } from '@react-icons/all-files/ai/AiOutlineCloseCircle';

const isBrowser = () => typeof window !== "undefined"

const CloseButtonWrapper = styled.div`
  position: fixed;
  top: 3px;
  right: 20%;
  color: ${(props) => (props.theme.name === 'dark' ? '#848484' : '#848484')};
  margin: 5px;
`;

export const NewsWrapper = () => {
    useWindowSize();
    const [display, setDisplay] = React.useState(false);
    const [disableDrag, setDisableDrag] = React.useState(false);

    const handleVisibility = ()=>{
      // if(disableDrag){
      (display) ? setDisplay(false) : setDisplay(true);
      // }
    }

    const handleStart = (e) => {
        setDisableDrag(true)
    }
    const handleStop = (e) => {
      setTimeout(function(){setDisableDrag(false)},500);
    }

    const dragHandlers = {onStart: handleStart, onStop: handleStop};
    // let scrnWidth = isBrowser() && window.innerWidth;

    return (
        <ThemeContext.Consumer>
            {(theme)=>
                <Draggable handle="strong" axis='y' bounds={{top:-50, bottom: 250}} disabled={disableDrag} {...dragHandlers}>
                  <NewsWrapperMain>
                      <NewsWrapperContent className={display ? 'showNews' : 'hideNews'} theme={theme}>
                          <TextWrapper className={display ? 'showText' : 'hideText'}>
                          <CloseButtonWrapper theme={theme} className="closeStyle" onMouseDown={()=>setDisplay(false)}>
                            <AiOutlineCloseCircle size={30} />
                          </CloseButtonWrapper>
                            <PlainText>Thank you for visiting!</PlainText><PlainText>Here you'll find the latest news and release notes.</PlainText>
                            <Title>Website Highlights</Title>
                              <dl>
                                  <dd>✨ Draggable news & release notes window - Manually created from scratch</dd>
                                  <dd>✨ Fully responsive - This website will auto adjust to any size and platform (phone/tablet/pc). Some elements will shift and resize based on the screen, e.g. project cards width, bar chart style and size, section titles and more</dd>
                                  <dd>✨ Dark/Light Theme - Also changes automatically based on AU sunset/sunrise</dd>
                                  <dd>✨ Auto-generated project cards - All saved in a DB and each record creates a new card, with dynamically generated sections based on the content, including ranks to add "special" ribbons and much more</dd>
                                  <dd>✨ Theme based content - Fonts, colours and certain photos changes based on the active theme</dd>
                              </dl>

                            <Title>ShayLavi.com v2.2 - Release Notes</Title>
                              <dl>
                                <dd>💎 Project date - Displayed on preview card</dd>
                                <dd>💎 Active ribbon - Marks on-going projects</dd>
                              </dl>

                            <Title>ShayLavi.com v2.1 - Release Notes</Title>
                              <dl>
                                <dd>💎 CSS performance improvement - Resolved delayed CSS on first page load</dd>
                                <dd>💎 CSS bugfix for iPhones - Bad gradient view</dd>
                              </dl>

                            <Title>ShayLavi.com v2 - Release Notes</Title>
                              <dl>
                                  <dd>💎 Added draggable popup window for news and release notes</dd>
                                  <dd>💎 Added lazy-loading to all images</dd>
                                  <dd>💎 All SVG files were optimized</dd>
                                  <dd>💎 Theme-based glow effect for Footer</dd>
                                  <dd>💎 Dynamic width for project-cards</dd>
                                  <dd>💎 Upgraded and resolved all NPM packages conflicts</dd>
                                  <dd>💎 Rewritten modal logic to remove unsupported plugin</dd>
                                  <dd>💎 Replaced unsupported photos gallery plugin</dd>
                              </dl>
                          </TextWrapper>
                      </NewsWrapperContent>
                    <strong>
                      <NewsWrapperToggler onClick={handleVisibility}>
                        <img
                            key="newspaper"
                            src={newspaper}
                            height='40px'
                            width='40px'                      
                            // className={`${stackImgStyle} ${
                            //     activeTheme && activeTheme.name === 'dark' ? stackIconDarkMode : stackIconLightMode
                            // }`}
                            alt="News & Release Notes"
                        />
                      </NewsWrapperToggler>
                    </strong>
                  </NewsWrapperMain>
                </Draggable>
            }
        </ThemeContext.Consumer>
    )};

function useWindowSize() {
    const [windowSize, setWindowSize] = React.useState({
      width: undefined,
      height: undefined,
    });
    React.useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      isBrowser() && window.addEventListener("resize", handleResize);
      handleResize();
      return () => isBrowser() && window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }
