import * as React from 'react';
import { LogoImage, TitleWrapper, MainContainer, ProjectTitle, Content } from './ModalContentStyles';
import { ThemeContext } from './ThemeContext';

import GalleryPage from '../components/GalleryPage';
import Video from './video';
import { GetGalleryPhotos } from './QueryGalleryImages';
import { GetProjectsPhotos } from './QueryProjectsImages';
import ProjectsImageManager from './ProjectsImageManager';

export const ModalContent = (props) => {
  const galleryCheck = GetGalleryPhotos(props.data.project_id);
  const projectsImgData = GetProjectsPhotos(props.data.image_title);

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <MainContainer theme={theme}>
          <LogoImage md={12} style={{maxWidth:"600px", alignSelf:"center"}}>
            {ProjectsImageManager(projectsImgData, props.data.image_title)}
            <TitleWrapper>
              <ProjectTitle theme={theme}>{props.data.title}</ProjectTitle>
            </TitleWrapper>
          </LogoImage>
          <Content md={12} dangerouslySetInnerHTML={{ __html: props.data.description }} />
          {props.data.grade && (
            <Content md={12}>
              <span style={{ display: 'flex', alignItems: 'baseline', fontSize: '18px' }}>
                <h5>Final Grade: </h5>&nbsp; {props.data.grade}
                {/* <h5>Final Grade: </h5>&nbsp; {props.data.grade}/100 {parseInt(props.data.grade) >= 85 ? ("High Distinction") ? "Distinction"} */}
              </span>
            </Content>
          )}
          <Content md={12}>
            <h5>Challenges</h5>
            <span dangerouslySetInnerHTML={{ __html: props.data.challenges }} />
          </Content>
          {props.data.stack_detailed && (
            <Content md={12}>
              <h5>Technology Used</h5>
              <ul id="techstack">
                {props.data.stack_detailed.split(',').map((t, i) => (
                  <li key={`techstack_${i}`}>{t}</li>
                ))}
              </ul>
            </Content>
          )}
          {galleryCheck && (
            <Content md={12}>
              <h5 style={{ marginBottom: '25px' }}>Project Photos</h5>
              <GalleryPage data={props.data} />
            </Content>
          )}
          {props.data.video && (
            <Content md={12}>
              <Video videoTitle={props.data.title} videoSrcURL={props.data.video} />
            </Content>
          )}
          {props.data.links && (
            <Content md={12}>
              <h5>{`Demo ${props.data.links.split(';').length > 1 ? "Links" : "Link"}`}</h5>
              {props.data.links.split(';').map((l, i) => (<><a key={`techstack_${i}`} href={l} target="_new">{l}</a><br /></>))}
            </Content>
          )}
        </MainContainer>
      )}
    </ThemeContext.Consumer>
  );
};
